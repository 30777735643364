
import { useEffect, useState } from 'react';
import './App.css';

function App() {
  var url = new URL(window.location.href);
  var redirect_url = url.searchParams.get("redirect_url");
  const [isShowGuide, setShowGuide] = useState(false);

  useEffect(() => {
    let timeout = null;
    if (redirect_url && redirect_url !== '') {
      var ua = navigator.userAgent || navigator.vendor || window.opera;
      if (ua.indexOf("FBAN") != -1 || ua.indexOf("FBAV") != -1) { //If opens in facebook browser
        if (navigator.userAgent.match(/Android/i)) { //If Android platform
          window.location.href = `intent:${redirect_url}#Intent;end`;
        } else {
          if (redirect_url.startsWith('http')) {
            redirect_url = redirect_url.replaceAll('https://', '').replaceAll('http://', '');;
          }
          window.location.href = `googlechrome://${redirect_url}`;
          timeout = setTimeout(() => {
            setShowGuide(true);
          }, 4000);
        }
      } else {
        window.location.href = redirect_url;
      }
    } else {
      window.location.href = 'https://hitek.com.vn'
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, []);

  return (
    <div className="App" style={{ width: '100vw', height: '100vh', padding: 20, backgroundColor: 'white' }}>
      &nbsp;

      {isShowGuide && 'Bạn cần cài đặt trình duyệt Google Chrome để được chuyển tiếp đến ứng dụng. Vui lòng cài đặt và thử lại.'}
      <br/>
      <br/>
      {isShowGuide && <a href="https://www.google.com/intl/vi/chrome/">Cài đặt ngay</a>}
    </div>
  );
}

export default App;
